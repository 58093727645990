<template>
  <div class="footer">
    {{ $store.state.app.lang === 'zh-CN' ? '版权归 ©️ 把爱传下去 所有 All CopyRights Reserved' : 'CopyRIght ©️ Payitforward  All CopyRights Reserved' }}
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.footer {
  position: relative;
  z-index: 999;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #1D2130;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: @sm-width) {
  .footer {
    font-size: 10px;
  }
}
</style>
