import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 重置样式以及全局样式
import '@/assets/style/reset.css'
import '@/assets/style/global.css'
// 全局组件
import '@/components'
// 全局指令
import '@/utils/directives'
// 国际化
import i18n from '@/lang'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
