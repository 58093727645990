const app = {
  namespaced: true,
  state: {
    lang: 'zh-CN'
  },
  mutations: {
    SET_LANG (state, lang) {
      state.lang = lang
    }
  },
  actions: {
  }
}

export default app
