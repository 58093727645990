export const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/layout'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        meta: {
          title: '首页'
        },
        component: () => import('@/views/home')
      }
    ]
  },
  {
    path: '/program',
    name: 'ProgramIndex',
    component: () => import('@/views/layout'),
    redirect: '/program/home',
    meta: {
      title: '公益项目'
    },
    children: [
      {
        path: 'home',
        name: 'Program',
        component: () => import('@/views/program')
      }
    ]
  },
  {
    path: '/news',
    name: 'NewsIndex',
    component: () => import('@/views/layout'),
    redirect: '/news/home',
    meta: {
      title: '公益动态'
    },
    children: [
      {
        path: 'home',
        name: 'News',
        component: () => import('@/views/news')
      },
      {
        path: 'detail',
        name: 'NewsDetail',
        meta: {
          title: '动态详情'
        },
        component: () => import('@/views/news/Detail')
      }
    ]
  },
  {
    path: '/about',
    name: 'AboutIndex',
    component: () => import('@/views/layout'),
    redirect: '/about/home',
    meta: {
      title: '关于我们'
    },
    children: [
      {
        path: 'home',
        name: 'About',
        component: () => import('@/views/about')
      }
    ]
  },
  {
    path: '/contact',
    name: 'ContactIndex',
    component: () => import('@/views/layout'),
    redirect: '/contact/home',
    meta: {
      title: '联系我们'
    },
    children: [
      {
        path: 'home',
        name: 'Contact',
        component: () => import('@/views/contact')
      }
    ]
  }
]
