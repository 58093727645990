<template>
  <div class="crumb">
    <span class="placeholder">{{ $t('当前位置') }}</span>
    <p
      v-for="(item, index) in crumbList"
      :key="index"
      class="crumb-item">
      <AliIcon
        name="icon-dongtaijiantou1"
        class="ali-icon"/>
      <span
        class="text"
        :class="(index === crumbList.length - 1) ? 'active' : ''"
        @click="handleCrumbItemClicked(item, index, index === crumbList.length - 1)">
        {{ $t(item.meta.title) }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      crumbList: []
    }
  },
  watch: {
    $route: {
      handler (value) {
        const { matched } = value
        if (matched && matched.length) {
          this.crumbList = matched.map(item => {
            const { meta, name } = item
            return {
              name,
              meta
            }
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    handleCrumbItemClicked (item, index, isLastItem) {
      if (isLastItem) return
      const { name } = item
      this.$router.push({
        name
      })
    }
  }
}
</script>

<style scoped lang="less">
.crumb {
  display: flex;
  align-items: center;
  .placeholder {
    font-size: 16px;
    color: #6D6E77;
    line-height: 16px;
    user-select: none;
  }
  .crumb-item {
    display: flex;
    align-items: center;
    .ali-icon {
      font-size: 20px;
      color: #6D6E77;
      margin: 0 3px 0 5px;
    }
    .text {
      font-size: 16px;
      color: #6D6E77;
      line-height: 16px;
      user-select: none;
      cursor: pointer;
      &.active {
        color: #1D2130!important;
        cursor: no-drop;
      }
      &:hover {
        color: #0056c7;
      }
    }
  }
}
@media (max-width: 750px) {
  .crumb {
    .placeholder {
      font-size: 14px;
      line-height: 14px;
    }
    .crumb-item {
      .ali-icon {
        font-size: 17px;
        color: #6D6E77;
        margin: 0 3px 0 3px;
      }
      .text {
        font-size: 14px;
        line-height: 14px;
        &.active {
          color: #1D2130;
        }
      }
    }
  }
}
</style>
