<template>
  <div class="header">
    <div class="view-container container">
      <div class="logo" @click="handleLogoClicked">
        <img src="@/assets/image/home/logo.png" alt="logo">
      </div>
      <!-- tab及语言切换 -->
      <div class="right-box">
        <ToggleTab class="tabs" />
        <ToggleLang class="lang" />
        <AliIcon
          name="icon-caidanicon"
          class="ali-icon"
          v-show="!showH5Tabs"
          @click="handleH5TabsToggle"
          v-clickoutside="handleCloseH5Tabs"/>
        <AliIcon
          name="icon-guanbiicon"
          class="ali-icon"
          v-show="showH5Tabs"
          @click="handleCloseH5Tabs"/>
      </div>
    </div>
    <!-- h5下的tab -->
    <transition name="drop-down">
      <div class="tabs-h5" v-show="showH5Tabs">
        <ToggleTab @toggled="showH5Tabs = false"></ToggleTab>
      </div>
    </transition>
    <!-- h5蒙层 -->
    <div class="tabs-mask animate__animated animate__fadeIn" v-show="showH5Tabs" v-no-through></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showH5Tabs: false
    }
  },
  methods: {
    // logo点击
    handleLogoClicked () {
      this.$router.push({
        name: 'Home'
      })
    },
    // h5 tab菜单展示
    handleH5TabsToggle () {
      this.showH5Tabs = !this.showH5Tabs
    },
    handleCloseH5Tabs () {
      this.showH5Tabs = false
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .logo {
      width: 176px;
      height: 50px;
      cursor: pointer;
      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .right-box {
      display: flex;
      align-items: center;
      .lang {
        margin-left: 50px;
      }
      .ali-icon {
        display: none;
      }
    }
  }
  .tabs-h5 {
    display: none;
  }
}
@media (max-width: @sm-width) {
  .header {
    height: 50px;
    .container {
      .logo {
        width: 115px;
        height: 38px;
      }
      .right-box {
        .tabs {
          display: none;
        }
        .lang {
          margin-left: 0;
        }
        .ali-icon {
          display: block;
          font-size: 24px;
          color: #6d6e77;
          margin-left: 10px;
        }
      }
    }
    .tabs-h5 {
      display: block;
      // position: fixed;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100%;
      // background: #000;
    }
    .tabs-mask {
      position: fixed;
      top: 50px;
      left: 0;
      z-index: 9;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .3);
    }
  }
}
@media (min-width: @sm-width) and (max-width: @md-width) {
  .header {
    .container {
      .logo {
        width: 176px;
        height: 50px;
      }
      .right-box {
        .lang {
          margin-left: 25px;
        }
      }
    }
  }
}
.drop-down-enter {
  opacity: 0;
  transform:translate(0px, -90px) scaleY(0.2);
}
.drop-down-leave-to {
  opacity: 0;
  transform:translate(0px, -90px) scaleY(0.2);
}
.drop-down-enter-active {
  transition: all 0.2s ease-in;
}
.drop-down-leave-active {
  transition: all 0.2s ease;
}
</style>
