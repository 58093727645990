/**
 * @description 禁止移动端蒙层滚动穿透 解决弹窗内容区滚动穿透到body的问题（覆盖率90%）
 * @description 用法
 * <ul v-no-through>
 *  <li></li>
 *  <li></li>
 * </ul>
 * https://juejin.cn/post/6905643392324239367
 **/

// 全局变量 targetY
var targetY = 0
export default {
  name: 'no-through',

  bind: function (el, binding) {
    // 在binding这个对象里添加属性，是为了在unbind的时候可以取到
    binding.handleTouchstart = function (event) {
      targetY = Math.floor(event.targetTouches[0].clientY) // 手指起始触摸位置
    }
    binding.handleTouchmove = function (event) {
      const newTargetY = Math.floor(event.targetTouches[0].clientY) // 手指滑动中触摸位置
      const sTop = el.scrollTop // 内容滚动到容器顶部的高度
      const sHeight = el.scrollHeight // 内容的可滚动高度
      const cliHeight = el.clientHeight // 当前内容容器的高度
      if (sTop <= 0 && newTargetY - targetY > 0 && event.cancelable) {
        // console.log('下拉到页面顶部')
        event.preventDefault()
      } else if (sTop >= sHeight - cliHeight && newTargetY - targetY < 0 && event.cancelable) {
        // console.log('上翻到页面底部')
        event.preventDefault()
      }
    }
    el.addEventListener('touchstart', binding.handleTouchstart)
    el.addEventListener('touchmove', binding.handleTouchmove, {
      passive: false
    })
  },

  unbind: function (el, binding) {
    // 重置全局变量 targetY
    targetY = 0
    el.removeEventListener('touchstart', binding.handleTouchstart)
    el.removeEventListener('touchmove', binding.handleTouchmove, {
      passive: false
    })
  }
}

// 最后再去 main.js 注册为全局指令，即可使用。
