import Vue from 'vue'
import VueI18n from 'vue-i18n'

import enLocale from './en.json'
import zhLocale from './zh-CN.json'

import detailEnLocale from './news-detail/en.json'
import detailZhLocale from './news-detail/zh-CN.json'

import store from '@/store'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...detailEnLocale
  },
  'zh-CN': {
    ...zhLocale,
    ...detailZhLocale
  }
}

const i18n = new VueI18n({
  locale: store.state.app.lang,
  messages,
  silentFallbackWarn: true,
  silentTranslationWarn: true
})

export {
  i18n as default
}
