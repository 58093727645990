<template>
<div
  class="lang"
  v-clickoutside="handleClose">
  <div
    class="lang-block"
    @click="handleToggleLang">
    <AliIcon
      name="icon-diqiu"
      class="icon"/>
    <span class="text">{{ activeLangText }}</span>
    <AliIcon
      name="icon-xiasanjiao"
      class="icon xiasanjiao"/>
  </div>
  <transition name="drop-down">
    <ul
      class="lang-selector"
      v-show="showSelector">
      <li
        class="lang-option"
        :class="activeLang === item.value ? 'active' : ''"
        v-for="item in langOptionList"
        :key="item.value"
        @click="handleSelectLang(item)">
        <span>{{ item.label }}</span>
        <AliIcon
          v-show="activeLang === item.value"
          name="icon-queding"
          class="ali-icon"/>
      </li>
    </ul>
  </transition>
</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data () {
    return {
      showSelector: false,
      langOptionList: [
        { label: 'CN', value: 'zh-CN' },
        { label: 'EN', value: 'EN' }
      ],
      activeLang: '',
      activeLangText: ''
    }
  },
  computed: {
    ...mapState('app', ['lang'])
  },
  watch: {
    lang: {
      handler (v) {
        const target = this.langOptionList.find((item) => item.value.toUpperCase() === v.toUpperCase())
        this.activeLangText = target ? target.label : 'CN'
        this.activeLang = target ? target.value : 'zh-CN'
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('app', ['SET_LANG']),
    handleToggleLang () {
      this.showSelector = !this.showSelector
    },
    // 选择语言
    handleSelectLang ({ value }) {
      this.showSelector = false
      // 修改i18n及storage中的语言
      this.$i18n.locale = value || 'zh-CN'
      this.SET_LANG(value || 'zh-CN')
    },
    handleClose () {
      this.showSelector = false
    }
  }
}
</script>

<style scoped lang="less">
.lang {
  position: relative;
  .lang-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 110px;
    height: 35px;
    padding: 0 15px;
    background: #0056C7;
    border-radius: 6px;
    cursor: pointer;
    .icon {
      font-size: 20px;
      color: #fff;
    }
    .xiasanjiao {
      font-size: 16px;
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 16px;
      user-select: none;
    }
  }
  .lang-selector {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    z-index: 999;
    width: 110px;
    padding: 0 15px;
    background: #FFFFFF;
    box-shadow: 0px 2px 16px 0px rgba(0, 86, 199, 0.2);
    border-radius: 12px;
    cursor: pointer;
    .lang-option {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      line-height: 42px;
      border-bottom: 1px solid #D8D8D8;
      box-sizing: content-box;
      font-size: 16px;
      user-select: none;
      &.active {
        color: #0056C7;
      }
      &:last-child {
        border-bottom: none;
      }
      .ali-icon {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: @sm-width) {
  .lang {
    position: relative;
    .lang-block {
      width: 70px;
      height: 24px;
      padding: 0 7px;
      border-radius: 6px;
      .icon {
        display: none;
      }
      .xiasanjiao {
        display: block;
        font-size: 16px;
      }
      .text {
        font-size: 14px;
        line-height: 14px;
      }
    }
    .lang-selector {
      width: 70px;
      padding: 0 7px;
      box-shadow: 0px 2px 16px 0px rgba(0, 86, 199, 0.2);
      border-radius: 6px;
      .lang-option {
        width: 100%;
        height: 28px;
        line-height: 28px;
        border-bottom: 1px solid #D8D8D8;
        font-size: 14px;
        .ali-icon {
          font-size: 16px;
        }
      }
    }
  }
}
.drop-down-enter {
  opacity: 0;
  transform:translate(0px, -25px) scaleY(0.2);
}
.drop-down-leave-to {
  opacity: 0;
  transform:translate(0px, -25px) scaleY(0.2);
}
.drop-down-enter-active {
  transition: all 0.2s ease-in;
}
.drop-down-leave-active {
  transition: all 0.2s ease;
}
</style>
