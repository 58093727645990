<template>
  <div class="program-box">
    <ul class="program-list">
      <li
        v-for="(item, index) in list"
        :key="item.id">
        <div
          class="program-item"
          :class="{ active: activeProgram === item.id, hover: hoverProgram === item.id }"
          @click="handleToggleProgram(item)"
          @mouseenter="handleEnterProgram(item)"
          @mouseleave="handleLeaveProgram(item)">
          <div class="left-box">
            <div class="img">
              <img
                :src="item.image"
                alt=""
                v-show="activeProgram !== item.id && hoverProgram !== item.id">
              <img
                :src="item.activeImage"
                alt=""
                v-show="activeProgram === item.id || hoverProgram === item.id">
            </div>
            <span class="text">
              {{ $t(item.text) }}
            </span>
          </div>
          <AliIcon
            class="ali-icon"
            name="icon-caidanjiantou"/>
          <AliIcon
            class="ali-icon-h5-down"
            name="icon-h5-caidanjiantouxia"/>
          <AliIcon
            class="ali-icon-h5-up"
            name="icon-h5-caidanjiantoushang"/>
        </div>
        <!-- h5端详情 -->
        <div
          class="program-detail-h5 animate__animated animate__flipInY"
          :class="showDetail && activeProgram === item.id ? 'active' : ''">
          <div
            v-for="(para, i) in item.details.pList"
            :key="i">
            <p class="paragraph">{{ $t(para) }}</p>
            <slot :name="`h5-slot-${index}-${i}`"></slot>
          </div>
        </div>
      </li>
    </ul>
    <!-- pc端详情 -->
    <div class="program-detail">
      <div
        class="program-detail-item animate__animated animate__fadeInUp"
        v-show="showDetail && activeProgram === item.id"
        v-for="(item, index) in list"
        :key="item.id">
        <p class="title">{{ $t(item.details.title) }}</p>
        <div class="scroll">
          <div
            v-for="(para, i) in item.details.pList"
            :key="i">
            <p class="paragraph">{{ $t(para) }}</p>
            <slot :name="`slot-${index}-${i}`"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default () {
        return []
      }
    },
    selectedProgram: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      // 点击激活项
      activeProgram: '',
      // 鼠标hover项
      hoverProgram: '',
      // 展示详情与否
      showDetail: true
    }
  },
  watch: {
    selectedProgram: {
      handler (value) {
        this.activeProgram = value
      },
      immediate: true
    }
  },
  mounted () {
    this.resizeCallback = () => {
      if (!this.showDetail) {
        this.showDetail = true
      }
    }
    window.addEventListener('resize', this.resizeCallback)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeCallback)
  },
  methods: {
    handleToggleProgram ({ id }) {
      // 如果在h5上点击的是已激活的tab 则切换详情的展示 750对应CSS中的@sm-width
      if (this.activeProgram === id && window.innerWidth <= 750) {
        this.showDetail = !this.showDetail
        return
      }
      this.activeProgram = id
      this.showDetail = true
    },
    handleEnterProgram ({ id }) {
      this.hoverProgram = id
    },
    handleLeaveProgram () {
      this.hoverProgram = ''
    }
  }
}
</script>

<style scoped lang="less">
.program-box {
  position: relative;
  z-index: 999;
  display: flex;
  margin-bottom: 53px;
  .program-list {
    width: 300px;
    margin-right: 80px;
    .program-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      margin-bottom: 6px;
      padding: 0 20px 0 8px;
      cursor: pointer;
      &.hover {
        background: rgba(0, 86, 199, 0.08);
        border-radius: 12px;
        .left-box {
          .text {
            color: #0056C7;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        background: rgba(0, 86, 199, 0.08);
        border-radius: 12px;
        .left-box {
          .text {
            color: #0056C7;
          }
        }
        .ali-icon {
          display: block;
        }
      }
      .left-box {
        display: flex;
        align-items: center;
        .img {
          width: 48px;
          height: 48px;
          margin-right: 14px;
          > img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .text {
          max-width: 180px;
          font-size: 16px;
          color: #1D2130;
          line-height: 20px;
          word-break: keep-all;
          user-select: none;
        }
      }
      .ali-icon {
        display: none;
        font-size: 20px;
        color: #0056C7;
      }
      .ali-icon-h5-down {
        display: none;
      }
      .ali-icon-h5-up {
        display: none;
      }
    }
    .program-detail-h5 {
      display: none;
    }
  }
  .program-detail {
    flex: 1;
    .program-detail-item {
      padding-top: 10px;
      .title {
        margin-bottom: 30px;
        font-size: 36px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
      }
      .scroll {
        // max-height: 248px;
        // overflow-y: scroll;
        .paragraph {
          margin-bottom: 25px;
          font-size: 16px;
          font-weight: 400;
          color: #6D6E77;
          line-height: 28px;
        }
      }
    }
  }
}
@media (max-width: @sm-width) {
  .program-box {
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 32px;
    .program-list {
      width: 100%;
      margin-right: 0;
      .program-item {
        width: 100%;
        height: 45px;
        margin-bottom: 10px;
        padding: 0 8px 0 4px;
        border-radius: 6px;
        background: rgba(216, 216, 216, 0.2);
        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          background: rgba(0, 86, 199, 0.08);
          border-radius: 6px;
          .left-box {
            .text {
              font-size: 16px;
              color: #0056C7;
            }
          }
          .ali-icon {
            display: none;
          }
          .ali-icon-h5-down {
            display: none;
          }
          .ali-icon-h5-up {
            display: block;
            font-size: 24px;
            color: #0056C7;
          }
        }
        .left-box {
          display: flex;
          align-items: center;
          .img {
            width: 40px;
            height: 40px;
            margin-right: 5px;
          }
          .text {
            max-width: 170px;
            font-size: 16px;
            line-height: 16px;
            word-break: keep-all;
          }
        }
        .ali-icon {
          display: none;
        }
        .ali-icon-h5-down {
          display: block;
          font-size: 24px;
          color: #6D6E77;
        }
        .ali-icon-h5-up {
          display: none;
        }
      }
      .program-detail-h5 {
        display: none;
        padding: 5px 8px 15px;
        &.active {
          display: block;
          .paragraph {
            margin-bottom: 20px;
            font-size: 14px;
            color: #6D6E77;
            line-height: 24px;
          }
        }
      }
    }
    .program-detail {
      display: none;
    }
  }
}
</style>
