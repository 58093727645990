<template>
  <div class="main-card">
    <div class="main-info">
      <p class="title">{{ $t(data.title) }}</p>
      <p
        class="sub-title"
        v-show="$store.state.app.lang === 'zh-CN'">
        {{ data.subtitle }}
      </p>
    </div>
    <div class="main-image">
      <img :src="data.image" alt="main" class="pc-img">
      <img :src="data.h5Image" alt="main-h5" class="h5-img">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // title subtitle image
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.main-card {
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  height: 360px;
  padding: 50px 50px 50px 80px;
  border-radius: 24px;
  border: 1px solid #D8D8D8;
  .main-info {
    margin-top: 94px;
    margin-right: 25px;
    white-space: nowrap;
    .title {
      position: relative;
      font-size: 54px;
      font-weight: 400;
      color: #1D2130;
      line-height: 54px;
      &::before {
        content: '';
        position: absolute;
        top: -40px;
        left: 0;
        width: 110px;
        height: 4px;
        background: linear-gradient(135deg, #6BC4E7 0%, #0056C7 100%);
        border-radius: 4px;
      }
    }
    .sub-title {
      margin-top: 24px;
      font-size: 34px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #1D2130;
      line-height: 34px;
      letter-spacing: 2px;
    }
  }
  .main-image {
    width: 750px;
    height: 260px;
    .pc-img {
      display: block;
    }
    .h5-img {
      display: none;
    }
    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
@media (max-width: @sm-width) {
  .main-card {
    height: 140px;
    padding: 15px;
    border-radius: 6px;
    .main-info {
      margin-top: 37px;
      .title {
        font-size: 22px;
        line-height: 22px;
        &::before {
          top: -20px;
          width: 55px;
          height: 2px;
          border-radius: 2px;
        }
      }
      .sub-title {
        margin-top: 10px;
        font-size: 14px;
        line-height: 14px;
      }
    }
    .main-image {
      width: 210px;
      height: 110px;
      .pc-img {
        display: none;
      }
      .h5-img {
        display: block;
      }
    }
  }
}
@media (min-width: @sm-width) and (max-width: @md-width) {
  .main-card {
    padding: 50px 50px 50px 50px;
    .main-info {
      .title {
        font-size: 35px;
        line-height: 35px;
        &::before {
        content: '';
          position: absolute;
          top: -30px;
          left: 0;
          width: 70px;
          height: 4px;
          background: linear-gradient(135deg, #6BC4E7 0%, #0056C7 100%);
          border-radius: 4px;
        }
      }
      .sub-title {
        margin-top: 14px;
        font-size: 28px;
        line-height: 28px;
      }
    }
    .main-image {
      width: 750px;
      height: 260px;
      .pc-img {
        display: block;
      }
      .h5-img {
        display: none;
      }
      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
