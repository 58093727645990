<template>
  <div>
    <!-- 首页展示 -->
    <div v-if="$route.name === 'Home'">
      <div class="left-blur">
        <img src="@/assets/image/home/left-blur.png" alt="left-blur">
      </div>
      <div class="right-blur">
        <img src="@/assets/image/home/right-blur.png" alt="right-blur">
      </div>
      <div class="bottom-blur">
        <img src="@/assets/image/home/bottom-blur.png" alt="bottom-blur">
      </div>
    </div>
    <!-- 其他内容页展示 -->
    <div v-else>
      <div class="content-left-blur">
        <img src="@/assets/image/home/left-blur.png" alt="content-left-blur">
      </div>
      <div class="content-right-blur">
        <img src="@/assets/image/home/content-right-blur.png" alt="content-right-blur">
      </div>
      <div class="content-bottom-blur">
        <img src="@/assets/image/home/bottom-blur.png" alt="content-bottom-blur">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.left-blur {
  position: absolute;
  top: -50px;
  left: -160px;
  width: 640px;
  height: 640px;
}
.right-blur {
  position: absolute;
  top: -120px;
  right: -150px;
  width: 600px;
  height: 600px;
}
.bottom-blur {
  position: absolute;
  bottom: -240px;
  left: 0;
  width: 100%;
  height: 440px;
}
.content-left-blur {
  position: absolute;
  top: -160px;
  left: -200px;
  width: 860px;
  height: 860px;
  z-index: -1;
}
.content-right-blur {
  position: absolute;
  top: -20px;
  right: -90px;
  width: 650px;
  height: 650px;
  z-index: -1;
}
.content-bottom-blur {
  position: absolute;
  bottom: -240px;
  left: 0;
  width: 100%;
  height: 440px;
  z-index: -1;
}
@media (max-width: @sm-width) {
  .left-blur {
    position: absolute;
    top: -80px;
    left: -180px;
    width: 320px;
    height: 320px;
  }
  .right-blur {
    position: absolute;
    top: -120px;
    right: -150px;
    width: 300px;
    height: 300px;
  }
  .bottom-blur {
    position: absolute;
    bottom: -140px;
    left: 0;
    width: 100%;
    height: 220px;
  }
  .content-left-blur {
    position: absolute;
    top: -80px;
    left: -160px;
    width: 430px;
    height: 430px;
    z-index: -1;
  }
  .content-right-blur {
    position: absolute;
    top: -40px;
    right: -100px;
    width: 300px;
    height: 300px;
    z-index: -1;
  }
  .content-bottom-blur {
    position: absolute;
    bottom: -140px;
    left: 0;
    width: 100%;
    height: 220px;
    z-index: -1;
  }
}
img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
