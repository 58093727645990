<template>
  <ul class="tab-list">
    <li
      class="tab-item"
      :class="item.name === activeTab ? 'active' : ''"
      v-for="(item, index) in tabList"
      :key="index"
      @click="handleToggleTab(item)">
      <span>{{ $t(item.label) }}</span>
      <AliIcon name="icon-h5-caidanqueding" class="ali-icon"/>
    </li>
  </ul>
</template>

<script>
export default {
  data () {
    return {
      tabList: [
        { label: '首页', name: 'Index' },
        { label: '公益项目', name: 'ProgramIndex' },
        { label: '公益动态', name: 'NewsIndex' },
        { label: '关于我们', name: 'AboutIndex' },
        { label: '联系我们', name: 'ContactIndex' }
      ],
      activeTab: ''
    }
  },
  watch: {
    $route: {
      // 路由改变时 自动切换tab
      handler (value) {
        const { matched } = value
        const target = this.tabList.find(i => matched.some(j => i.name === j.name))
        this.activeTab = target ? target.name : target
      },
      immediate: true
    }
  },
  methods: {
    handleToggleTab ({ name }) {
      this.activeTab = name
      this.$router.push({
        name
      })
      this.$emit('toggled')
    }
  }
}
</script>

<style scoped lang="less">
.tab-list {
  display: flex;
  .tab-item {
    position: relative;
    // min-width: 90px;
    margin-right: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #1D2130;
    line-height: 16px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    &.active {
      // font-size: 20px;
      color: #0056C7;
      transition: all .2s ease;
      &::after {
        width: 100%;
      }
    }
    &:last-child {
      margin-right: 0;
    }
    .ali-icon {
      display: none;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
    &::after {
      position: absolute;
      bottom: -22px;
      left: 0;
      content: '';
      width: 0;
      height: 2px;
      background: #0056C7;
      border-radius: 4px;
      transition: all .2s ease;
    }
  }
}
@media (max-width: @sm-width) {
  .tab-list {
    position: relative;
    z-index: 999;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: linear-gradient(135deg, #6BC4E7 0%, #0056C7 100%);
    .tab-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 45px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);
      min-width: auto;
      margin-right: 0;
      padding: 0 15px;
      font-size: 14px;
      color:  rgba(255, 255, 255, 0.65);
      line-height: 16px;
      &:last-child {
        margin-right: 0;
        border-bottom: none;
      }
      &.active {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.65);
        .ali-icon {
          display: block;
        }
      }
      .ali-icon {
        display: none;
        font-size: 20px;
        color: #fff;
      }
      &::after {
        display: none;
      }
    }
  }
}
@media (min-width: @sm-width) and (max-width: @md-width) {
  .tab-list {
    .tab-item {
      margin-right: 24px;
    }
  }
}
</style>
